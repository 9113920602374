<template>
	<div class="washHigh">
		<!-- 材料导航 -->
		<material-banner :menuIndex="5"></material-banner>
		
		<!-- 产品特点 -->
		<div class="region-com region-trait k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.washhighProductFeatures') }}</span>
				<span class="region-title-deputy">{{ $t('m.washhighHightemperaturewashlabels') }}</span>
			</div>
			<div class="region-desc k_flex_wrap_jcsb">
				<div v-for="(item,index) in traitList" class="region-desc-single k_flex" :key="index" :class="index>0?'normal':'special'">
					<img :src="washHighUrl+item.img" class="region-desc-left"/>
					<div class="region-desc-right k_flex_column_aifs">
						<span>{{item.title}}</span>
						<span>{{item.content}}</span>
						<span v-if="item.contents">{{item.contents}}</span>
						<div v-if="item.special" class="k_flex_aic region-desc-right-bottom">
							<img :src="washHighUrl+item.special_img"/>
							<span>{{item.special}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 产品展示 -->
		<div class="region-com region-display k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.washhightraitListProducts') }}</span>
				<span class="region-title-deputy">{{ $t('m.washhightraitListYouridealwashlabel') }}</span>
			</div>
			<div class="region-desc k_flex_jcsb_aic">
				<img :src="washHighUrl+'display_01.jpg'" class="region-desc-left"/>
				<div class="region-desc-right k_flex_column_jcsb_aic">
					<img :src="washHighUrl+'display_02.jpg'" class="region-desc-right-top"/>
					<div class="region-desc-right-bottom">{{ $t('m.washhightraitListNoneedof') }}</div>
				</div>
			</div>
		</div>
		
		<!-- 适用行业 -->
		<div class="region-com region-suitable k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.washhightraitListApplicableindustries') }}</span>
				<span class="region-title-deputy">{{ $t('m.washhightraitListMeettheneedsof') }}</span>
			</div>
			<div class="region-desc k_flex_wrap_jcc">
				<div class="region-desc-single k_flex" v-for="(item,index) in suitableList" :key="index">
					<img class="region-desc-single-left" :src="washHighUrl+item.img" />
					<div class="region-desc-single-right k_flex_column_aifs">
						<span>{{item.title}}</span>
						<span></span>
						<span>{{item.content}}</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 产品规格 -->
		<div class="region-com region-size k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">产品规格、特性</span>
			</div>
			<div class="region-cont">
				<el-table
					:data="tableData"
					:span-method="objectSpanMethod"
					border
					style="width: 100%;font-size: 16px;">
					<el-table-column
						prop="name"
						label="产品名称"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Product')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="type"
						label="产品型号"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Model')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="standards"
						label="产品规格"
						align="center"
						width="160">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Specification')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="metre"
						label="米数"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Length')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="introduce"
						label="介绍"
						align="center"
						width="599">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Introduction')}}</div>
						</template>
						<template slot-scope="scope">
							<div class="region-cont-introduce">
								<div class="region-cont-introduce-single" v-for="(item,index) in scope.row.introduce">
									<span>{{item.title}}</span>
									<span>{{item.content}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<img :src="washHighUrl+$t('m.washHighsize')" class="region-desc">
		</div>
	</div>
</template>

<script>
import materialBanner from '@c/materialBanner/materialBanner'
export default{
	components:{
		materialBanner,
	},
	data() {
		return {
			// suitableList:[
			// 	{
			// 		title:'鞋帽行业',
			// 		img:'suitable_01.png',
			// 		content:'表面光滑，适用于标签信息、logo等介绍的承载，牢固不掉色'
			// 	},
			// 	{
			// 		title:'日用品行业',
			// 		img:'suitable_02.png',
			// 		content:'耐干摩擦，sgs耐摩擦检测达到4级以上'
			// 	},
			// 	{
			// 		title:'服装',
			// 		img:'suitable_03.png',
			// 		content:'用在衣服标签，可以跟衣服一起用高温熨烫机熨烫，高温熨烫后不变形不发色  '
			// 	},
			// 	{
			// 		title:'食品饲料包装行业',
			// 		img:'suitable_04.png',
			// 		content:'热敏打印更高效便捷，耐刮擦'
			// 	}
			// ],
			// tableData: [
			// 	{
			// 		name: '热敏高温水洗唛',
			// 		type: 'Tht903',
			// 		standards: '1085mm',
			// 		metre: '3000m',
			// 		introduce: [
			// 			{
			// 				title:'耐水洗：',
			// 				content:'经过60°常温加洗衣液水洗，洗5次每次45分钟后依旧不褪色，不脱层；'
			// 			},
			// 			{
			// 				title:'耐刮擦：',
			// 				content:'干摩擦色牢度4级以上；'
			// 			},
			// 			{
			// 				title:'长久保存：',
			// 				content:'团队经过长时间研发的可长久保存的热敏材料，十年仍不褪色；'
			// 			},
			// 			{
			// 				title:'显色浓度高字迹清晰：',
			// 				content:'热敏打印出来显色浓度高字迹清晰可见；'
			// 			},
			// 			{
			// 				title:'节约成本降低排废：',
			// 				content:'传统碳带打印的需要不定时更换碳带，增加成本。相对之下热敏打印不仅节约成本更是环保首选；'
			// 			},
			// 			{
			// 				title:'防酒精：',
			// 				content:'可浸泡在酒精中超过5分钟不褪色；'
			// 			}
			// 		]
			// 	}, 
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1090mm',
			// 		metre: '6000m',
			// 		introduce: []
			// 	}
			// ],
			// traitList:[
			// 	{
			// 		title:'耐高温',
			// 		content:'蒸汽熨烫不发色',
			// 		contents:'耐高温熨烫，130度蒸汽熨斗熨5次',
			// 		special:'耐高温130度',
			// 		img:'trait_01.jpg',
			// 		special_img:'trait_logo.png'
			// 	},
			// 	{
			// 		title:'耐水洗',
			// 		content:'60°机洗，每次洗45分钟，洗5次，洗涤的时候会放洗衣液',
			// 		img:'trait_02.jpg'
			// 	},
			// 	{
			// 		title:'长久保存',
			// 		content:'团队经过长时间研发的可长久保存的热敏材料，十年仍不褪色',
			// 		img:'trait_03.jpg'
			// 	},
			// 	{
			// 		title:'防酒精',
			// 		content:'可浸泡在酒精中超过5分钟不褪色',
			// 		img:'trait_04.jpg'
			// 	},
			// 	{
			// 		title:'支持多型号打印',
			// 		content:'不需要特定的高温打印头，TSC、斑马正常型号都能均匀出色、保持高清晰',
			// 		img:'trait_05.jpg'
			// 	}
			// ]
		}
	},
	computed: {
		washHighUrl() {
		  return 'https://oss-www.kymasf.com/img/material/washHigh/'
		},
		traitList(){
			return [
				{
					title:this.$t('m.washhightraitListHighTemperatureresistant'),
					content:this.$t('m.washhightraitListNodiscoloration'),
					contents:this.$t('m.washhightraitListResistanttohigh'),
					special:this.$t('m.washhightraitListResistant'),
					img:'trait_01.jpg',
					special_img:'trait_logo.png'
				},
				{
					title:this.$t('m.washhightraitListWaterResistant'),
					content:this.$t('m.washhightraitListtimesofmachine'),
					img:'trait_02.jpg'
				},
				{
					title:this.$t('m.washhightraitListLongshelflife'),
					content:this.$t('m.washhightraitListlastingthermal'),
					img:'trait_03.jpg'
				},
				{
					title:this.$t('m.washhightraitListAlcoholresistant'),
					content:this.$t('m.washhightraitListNocolorfadingafter'),
					img:'trait_04.jpg'
				},
				{
					title:this.$t('m.washhightraitListCompatiblewithvariousprinters'),
					content:this.$t('m.washhightraitListNoneedforspecific'),
					img:'trait_05.jpg'
				}
			]
		},
		suitableList(){
			
			return[
				{
					title:this.$t('m.washhightraitListShoesandhatsindustry'),
					img:'suitable_01.png',
					content:this.$t('m.washhightraitListSmoothsurface')
				},
				{
					title:this.$t('m.washhightraitListConsumergoodsindustry'),
					img:'suitable_02.png',
					content:this.$t('m.washhightraitListResistanttodry')
				},
				{
					title:this.$t('m.washhightraitListClothingindustry'),
					img:'suitable_03.png',
					content:this.$t('m.washhightraitListUsedaslabels')
				},
				{
					title:this.$t('m.washhightraitListFoodandfeedingpackaging'),
					img:'suitable_04.png',
					content:this.$t('m.washhightraitListThermalprinting')
				}
			]
		},
		tableData(){
			return [
				{
					name: this.$t('m.tableDataHighTemperatureThermalWashLabel'),
					type: 'Tht903',
					standards: '1085mm',
					metre: '3000m',
					introduce: [
						{
							title:this.$t('m.tableDataNocolourfadingtitle'),
							content:this.$t('m.tableDataNocolourfading')
						},
						{
							title:this.$t('m.tableDataScratchResistanttitle'),
							content:this.$t('m.tableDataScratchResistant')
						},
						{
							title:this.$t('m.tableDataLongshelflifetitle'),
							content:this.$t('m.tableDataLongshelflife')
						},
						{
							title:this.$t('m.tableDataHighcolordensitytitle'),
							content:this.$t('m.tableDataHighcolordensity')
						},
						{
							title:this.$t('m.tableDataCostsavingandwastetitle'),
							content:this.$t('m.tableDataCostsavingandwaste')
						},
						{
							title:this.$t('m.tableDataAlcoholresistanttitle'),
							content:this.$t('m.tableDataAlcoholresistant')
						}
					]
				}, 
				{
					name: '',
					type: '',
					standards: '1090mm',
					metre: '6000m',
					introduce: []
				}
			]
		} 
	},
	mounted() {
		
	},
	methods: {
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
				if (rowIndex % 4 === 0) {
					return {
						rowspan: 4,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.washHigh{
	width: 100%;
	height: 100%;
	background: #fff;
	// 区域公共样式
	.region-com{
		width: 100%;
		.region-title{
			.region-title-main{
				margin-top: 48px;
				height: 48px;
				font-size: 42px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2E3136;
				line-height: 48px;
				// border-bottom: 2px dashed #535353;
				// padding-bottom: 15px;
				// margin-bottom: 15px
				margin: 0;
				padding: 0;
				border: none;
			}
			.region-title-deputy{
				width: 981px;
				font-size: 20px;
				margin-top: 10px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2C3F64;
				line-height: 24px;
				text-align: center;
			}
		}
	}
	
	//产品特点
	.region-trait{
		.region-title{
			margin-top: 36px;
		}
		.region-desc{
			width: 1200px;
			// padding: 46px 144px;
			// height: 362px;
			// background: #257FDB;
			margin: 34px 0 90px 0;
			box-sizing: border-box;
			.region-desc-single{
				padding: 12px;
				box-sizing: border-box;
				background: #5C2C1C;
			}
			.normal{
				width: 593px;
				margin-top: 14px;
				.region-desc-right{
					padding: 0 24px 0 24px;
					span{
						&:nth-child(1){
							font-size: 30px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
							margin-bottom: 17px;
						}
						&:nth-child(2){
							font-size: 20px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
						}
					}
				}
			}
			.special{
				width: 1200px;
				.region-desc-right{
					padding: 72px 24px 0 30px;
					position: relative;
					span{
						&:nth-child(1){
							font-size: 30px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
							margin-bottom: 17px;
						}
						&:nth-child(n+2){
							font-size: 22px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
						}
					}
					.region-desc-right-bottom{
						position: absolute;
						bottom: 30px;
						margin-left: 8px;
						span{
							margin-left: 20px;
							font-size: 30px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #FF4546;
						}
					}
				}
			}
		}
	}
	
	//产品展示
	.region-display{
		background: #EFF1F2;
		.region-title{
			margin-top: 55px;
		}
		.region-cont{
			
		}
		.region-desc{
			width: 1200px;
			margin-bottom: 66px;
			margin-top: 45px;
			.region-desc-right{
				height: 781px;
				.region-desc-right-bottom{
					width: 574px;
					height: 256px;
					background: #5C2C1C;
					font-size: 42px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 60px;
					padding: 40px 60px;
					box-sizing: border-box;
				}
			}
		}
	}
	
	//适用行业
	.region-suitable{
		margin: 109px 0 76px 0;
		.region-title{
			
		}
		.region-cont{
			
		}
		.region-desc{
			width: 1200px;
			margin-top: 40px;
			.region-desc-single{
				width: 585px;
				height: 200px;
				background: #5C2C1C;
				box-sizing: border-box;
				padding: 25px 32px 25px 28px;
				margin-bottom: 30px;
				&:nth-child(2n){
					// margin-right: 40px;
				}
				&:nth-child(2n+1){
					margin-right: 30px;
				}
				&:nth-child(n+3){
					margin-bottom: 0px;
				}
				.region-desc-single-left{
					// background: #fff;
					width: 150px;
					height: 150px;
					border-radius: 50%;
					margin-right: 24px;
				}
				.region-desc-single-right{
					width: 351px;
					box-sizing: border-box;
					// padding: 8px 0 0 0;
					span{
						&:nth-child(1){
							font-size: 38px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
							line-height: 48px;
						}
						&:nth-child(2){
							width: 52px;
							height: 10px;
							margin: 10px 0;
							// background: #FFFFFF;
						}
						&:nth-child(3){
							font-size: 16px;
							font-family: Adobe Heiti Std;
							font-weight: normal;
							color: #FFFFFF;
							line-height: 18px;
						}
					}
				}
			}
		}
	}
	
	//产品规格
	.region-size{
		background: #EFF1F2;
		.region-title{
			margin-top: 40px;
		}
		.region-cont{
			margin-top: 30px;
			width: 1200px;
			background: #B4C7D0;
			padding: 10px;
			border-radius: 8px;
			box-sizing: border-box;
			font-size: 16px;
			.region-cont-introduce{
				.region-cont-introduce-single{
					font-size: 16px;
					text-align: left;
				}
			}
		}
		.region-desc{
			width: 1200px;
			margin-top: 20px;
			margin-bottom: 56px;
		}
	}
}
</style>